import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user',
      name: 'user-index',
      component: () => import('@/views/User/Index.vue'),
      meta: {
        pageTitle: 'User',
        breadcrumb: [
          {
            text: 'User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-user',
      name: 'add-user',
      component: () => import('@/views/User/Add.vue'),
      meta: {
        pageTitle: 'Tambah User',
        breadcrumb: [
          {
            text: 'Add User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-user',
      name: 'edit-user',
      component: () => import('@/views/User/Edit.vue'),
      meta: {
        pageTitle: 'Edit User',
        breadcrumb: [
          {
            text: 'Edit User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/mapel',
      name: 'mapel-index',
      component: () => import('@/views/Mapel/Index.vue'),
      meta: {
        pageTitle: 'Mata Pelajaran',
        breadcrumb: [
          {
            text: 'Mata Pelajaran',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-mapel',
      name: 'add-mapel',
      component: () => import('@/views/Mapel/Add.vue'),
      meta: {
        pageTitle: 'Tambah Mata Pelajaran',
        breadcrumb: [
          {
            text: 'Tambah Mata Pelajaran',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-mapel/:id',
      name: 'edit-mapel',
      component: () => import('@/views/Mapel/Edit.vue'),
      meta: {
        pageTitle: 'Edit Mata Pelajaran',
        breadcrumb: [
          {
            text: 'Edit Mata Pelajaran',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kelas',
      name: 'kelas-index',
      component: () => import('@/views/Kelas/Index.vue'),
      meta: {
        pageTitle: 'Kelas',
        breadcrumb: [
          {
            text: 'Kelas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-kelas',
      name: 'add-kelas',
      component: () => import('@/views/Kelas/Add.vue'),
      meta: {
        pageTitle: 'Tambah Kelas',
        breadcrumb: [
          {
            text: 'Tambah Kelas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-kelas/:id',
      name: 'edit-kelas',
      component: () => import('@/views/Kelas/Edit.vue'),
      meta: {
        pageTitle: 'Edit Kelas',
        breadcrumb: [
          {
            text: 'Edit Kelas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/siswa',
      name: 'siswa-index',
      component: () => import('@/views/Siswa/Index.vue'),
      meta: {
        pageTitle: 'Data Siswa',
        breadcrumb: [
          {
            text: 'Data Siswa',
            active: true,
          },
        ],
      },
    },
    {
      path: '/jenis-ujian',
      name: 'jenis-ujian-index',
      component: () => import('@/views/Jenis-Ujian/Index.vue'),
      meta: {
        pageTitle: 'Jenis Ujian',
        breadcrumb: [
          {
            text: 'Jenis Ujian',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-jenis-ujian',
      name: 'add-jenis-ujian',
      component: () => import('@/views/Jenis-Ujian/Add.vue'),
      meta: {
        pageTitle: 'Tambah Jenis Ujian',
        breadcrumb: [
          {
            text: 'Tambah Jenis Ujian',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-jenis-ujian/:id',
      name: 'edit-jenis-ujian',
      component: () => import('@/views/Jenis-Ujian/Edit.vue'),
      meta: {
        pageTitle: 'Edit Jenis Ujian',
        breadcrumb: [
          {
            text: 'Edit Jenis Ujian',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ranking-jenis-ujian/:id_jenis_ujian',
      name: 'ranking-jenis-ujian',
      component: () => import('@/views/Jenis-Ujian/Ranking.vue'),
      meta: {
        pageTitle: 'Ranking Jenis Ujian',
        breadcrumb: [
          {
            text: 'Ranking Jenis Ujian',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ujian/:id_jenis_ujian',
      name: 'ujian-index',
      component: () => import('@/views/Ujian/Index.vue'),
      meta: {
        pageTitle: 'Data Ujian',
        breadcrumb: [
          {
            text: 'Jenis Ujian',
            to: { name: 'jenis-ujian-index' },
          },
          {
            text: 'Ujian',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-ujian/:id_jenis_ujian',
      name: 'add-ujian',
      component: () => import('@/views/Ujian/Add.vue'),
      meta: {
        pageTitle: 'Tambah Data Ujian',
        breadcrumb: [
          {
            text: 'Jenis Ujian',
            to: { name: 'jenis-ujian-index' },
          },
          {
            text: 'Ujian',
            to: { name: 'ujian-index' },
          },
          {
            text: 'Tambah Data Ujian',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-ujian/:id_jenis_ujian/:id',
      name: 'edit-ujian',
      component: () => import('@/views/Ujian/Edit.vue'),
      meta: {
        pageTitle: 'Edit Data Ujian',
        breadcrumb: [
          {
            text: 'Jenis Ujian',
            to: { name: 'jenis-ujian-index' },
          },
          {
            text: 'Ujian',
            to: { name: 'ujian-index' },
          },
          {
            text: 'Edit Data Ujian',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ujian/:idUjian/result',
      name: 'nilai-ujian',
      component: () => import('@/views/Ujian/Nilai-ujian.vue'),
      meta: {
        pageTitle: 'Data Nilai',
        breadcrumb: [
          {
            text: 'Data Nilai',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ujian/result/:idUjian/:idSiswa/:id',
      name: 'rekap-jawaban',
      component: () => import('@/views/Ujian/Rekap-jawaban.vue'),
      meta: {
        pageTitle: 'Data Rekap Jawaban',
        breadcrumb: [
          {
            text: 'Data Rekap Jawaban',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ujian/:id_ujian/:id_jenis_ujian/soal',
      name: 'soal-index',
      component: () => import('@/views/Soal/Index.vue'),
      meta: {
        pageTitle: 'Data Soal',
        breadcrumb: [
          {
            text: 'Ujian',
            to: { name: 'ujian-index' },
          },
          {
            text: 'Data Soal',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ujian/:id_ujian/:id_jenis_ujian/add-soal',
      name: 'add-soal',
      component: () => import('@/views/Soal/Add.vue'),
      meta: {
        pageTitle: 'Data Soal',
        breadcrumb: [
          {
            text: 'Ujian',
            to: { name: 'ujian-index' },
          },
          {
            text: 'Soal',
            to: { name: 'soal-index' },
          },
          {
            text: 'Tambah Soal',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ujian/:id_ujian/:id_jenis_ujian/edit-soal/:id',
      name: 'edit-soal',
      component: () => import('@/views/Soal/Edit.vue'),
      meta: {
        pageTitle: 'Edit Soal',
        breadcrumb: [
          {
            text: 'Ujian',
            to: { name: 'ujian-index' },
          },
          {
            text: 'Soal',
            to: { name: 'soal-index' },
          },
          {
            text: 'Edit Soal',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kelas/sd',
      name: 'kelas-sd',
      component: () => import('@/views/Materi/SD/Kelas-sd.vue'),
      meta: {
        pageTitle: 'Kelas SD',
        breadcrumb: [
          {
            text: 'Kelas SD',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kelas/smp',
      name: 'kelas-smp',
      component: () => import('@/views/Materi/SMP/Kelas-smp.vue'),
      meta: {
        pageTitle: 'Kelas SMP',
        breadcrumb: [
          {
            text: 'Kelas SMP',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kelas/sma',
      name: 'kelas-sma',
      component: () => import('@/views/Materi/SMA/Kelas-sma.vue'),
      meta: {
        pageTitle: 'Kelas SMA IPA',
        breadcrumb: [
          {
            text: 'Kelas SMA IPA',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kelas/sma-ips',
      name: 'kelas-sma-ips',
      component: () => import('@/views/Materi/SMAIPS/Kelas-smaips.vue'),
      meta: {
        pageTitle: 'Kelas SMA IPS',
        breadcrumb: [
          {
            text: 'Kelas SMA IPS',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kelas/sbmptn',
      name: 'kelas-sbmptn',
      component: () => import('@/views/Materi/SBMPTN/Kelas-sbmptn.vue'),
      meta: {
        pageTitle: 'Kelas SBMPTN',
        breadcrumb: [
          {
            text: 'Kelas SBMPTN',
            active: true,
          },
        ],
      },
    },
    {
      path: '/mapel-materi/:namaKelas/:idKelas',
      name: 'mapel-materi',
      component: () => import('@/views/Materi/Mapel-materi.vue'),
      meta: {
        pageTitle: 'Mata Pelajaran',
        breadcrumb: [
          {
            text: 'Mata Pelajaran',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bab-materi/:idKelas/:idMapel',
      name: 'bab-materi',
      component: () => import('@/views/Materi/Bab-materi.vue'),
      meta: {
        pageTitle: 'Bab Materi',
        breadcrumb: [
          {
            text: 'Mata Pelajaran Materi',
            to: { name: 'mapel-materi' },
          },
          {
            text: 'Bab Materi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bab-materi/:idKelas/:idMapel/add',
      name: 'add-bab-materi',
      component: () => import('@/views/Materi/Add-bab-materi.vue'),
      meta: {
        pageTitle: 'Tambah Bab Materi',
        breadcrumb: [
          {
            text: 'Mata Pelajaran',
            to: { name: 'mapel-materi' },
          },
          {
            text: 'Bab Materi',
            to: { name: 'bab-materi' },
          },
          {
            text: 'Tambah Bab Materi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bab-materi/:idKelas/:idMapel/:id/edit',
      name: 'edit-bab-materi',
      component: () => import('@/views/Materi/Edit-bab-materi.vue'),
      meta: {
        pageTitle: 'Edit Bab Materi',
        breadcrumb: [
          {
            text: 'Mata Pelajaran',
            to: { name: 'mapel-materi' },
          },
          {
            text: 'Bab Materi',
            to: { name: 'bab-materi' },
          },
          {
            text: 'Edit Bab Materi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/materi/:idKelas/:idMapel/:idBab',
      name: 'materi',
      component: () => import('@/views/Materi/Materi.vue'),
      meta: {
        pageTitle: 'Materi',
        breadcrumb: [
          {
            text: 'Mata Pelajaran Materi',
            to: { name: 'mapel-materi' },
          },
          {
            text: 'Bab Materi',
            to: { name: 'bab-materi' },
          },
          {
            text: 'Materi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/detail-materi/:idKelas/:idMapel/:idBab/:idMateri',
      name: 'detail-materi',
      component: () => import('@/views/Materi/Detail-materi.vue'),
      meta: {
        pageTitle: 'Detail Materi',
        breadcrumb: [
          {
            text: 'Mata Pelajaran Materi',
            to: { name: 'mapel-materi' },
          },
          {
            text: 'Bab Materi',
            to: { name: 'bab-materi' },
          },
          {
            text: 'Materi',
            to: { name: 'materi' },
          },
          {
            text: 'Detail Materi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-materi/:idKelas/:idMapel/:idBab',
      name: 'add-materi',
      component: () => import('@/views/Materi/Add-materi.vue'),
      meta: {
        pageTitle: 'Tambah Materi',
        breadcrumb: [
          {
            text: 'Mata Pelajaran Materi',
            to: { name: 'mapel-materi' },
          },
          {
            text: 'Bab Materi',
            to: { name: 'bab-materi' },
          },
          {
            text: 'Materi',
            to: { name: 'materi' },
          },
          {
            text: 'Tambah Materi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-materi/:idKelas/:idMapel/:idBab/:idMateri',
      name: 'edit-materi',
      component: () => import('@/views/Materi/Edit-materi.vue'),
      meta: {
        pageTitle: 'Edit Materi',
        breadcrumb: [
          {
            text: 'Mata Pelajaran Materi',
            to: { name: 'mapel-materi' },
          },
          {
            text: 'Bab Materi',
            to: { name: 'bab-materi' },
          },
          {
            text: 'Materi',
            to: { name: 'materi' },
          },
          {
            text: 'Edit Materi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/quiz/:id_bab/soal',
      name: 'quiz-index',
      component: () => import('@/views/Materi/Quiz/Index.vue'),
      meta: {
        pageTitle: 'Data Soal Quiz',
        breadcrumb: [
          {
            text: 'Bab',
            to: { name: 'bab-materi' },
          },
          {
            text: 'Data Soal',
            active: true,
          },
        ],
      },
    },
    {
      path: '/quiz/:id_bab/add-soal-quiz',
      name: 'add-soal-quiz',
      component: () => import('@/views/Materi/Quiz/Add.vue'),
      meta: {
        pageTitle: 'Data Soal Quiz',
        breadcrumb: [
          {
            text: 'Bab',
            to: { name: 'bab-materi' },
          },
          {
            text: 'Quiz',
            to: { name: 'quiz-index' },
          },
          {
            text: 'Tambah Soal Quiz',
            active: true,
          },
        ],
      },
    },
    {
      path: '/quiz/:id_bab/edit-soal-quiz/:id',
      name: 'edit-soal-quiz',
      component: () => import('@/views/Materi/Quiz/Edit.vue'),
      meta: {
        pageTitle: 'Edit Soal Quiz',
        breadcrumb: [
          {
            text: 'Bab',
            to: { name: 'bab-materi' },
          },
          {
            text: 'Quiz',
            to: { name: 'quiz-index' },
          },
          {
            text: 'Edit Soal Quiz',
            active: true,
          },
        ],
      },
    },
    {
      path: '/banner',
      name: 'banner-index',
      component: () => import('@/views/Banner/Index.vue'),
      meta: {
        pageTitle: 'Banner',
        breadcrumb: [
          {
            text: 'Banner',
            active: true,
          },
        ],
      },
    },
    {
      path: '/banner/add',
      name: 'banner-add',
      component: () => import('@/views/Banner/Add.vue'),
      meta: {
        pageTitle: 'Tambah Banner',
        breadcrumb: [
          {
            text: 'Tambah Banner',
            active: true,
          },
        ],
      },
    },
    {
      path: '/banner/edit/:id',
      name: 'banner-edit',
      component: () => import('@/views/Banner/Edit.vue'),
      meta: {
        pageTitle: 'Edit Banner',
        breadcrumb: [
          {
            text: 'Edit Banner',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/riasec',
      name: 'riasec-index',
      component: () => import('@/views/Psikotes/Riasec/Index.vue'),
      meta: {
        pageTitle: 'Riasec',
        breadcrumb: [
          {
            text: 'Riasec',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/riasec/add',
      name: 'riasec-add',
      component: () => import('@/views/Psikotes/Riasec/Add.vue'),
      meta: {
        pageTitle: 'Tambah Test Riasec',
        breadcrumb: [
          {
            text: 'Tambah Test Riasec',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/riasec/edit/:id',
      name: 'riasec-edit',
      component: () => import('@/views/Psikotes/Riasec/Edit.vue'),
      meta: {
        pageTitle: 'Edit Test Riasec',
        breadcrumb: [
          {
            text: 'Edit Test Riasec',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/riasec/soal/:id_riasec_test',
      name: 'riasec-soal-index',
      component: () => import('@/views/Psikotes/Riasec/Soal/Index.vue'),
      meta: {
        pageTitle: 'Soal Riasec',
        breadcrumb: [
          {
            text: 'Soal Riasec',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/riasec/soal-add/:id_riasec_test',
      name: 'riasec-soal-add',
      component: () => import('@/views/Psikotes/Riasec/Soal/Add.vue'),
      meta: {
        pageTitle: 'Tambah Soal Riasec',
        breadcrumb: [
          {
            text: 'Tambah Soal Riasec',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/riasec/soal-edit/:id_riasec_test/:id',
      name: 'riasec-soal-edit',
      component: () => import('@/views/Psikotes/Riasec/Soal/Edit.vue'),
      meta: {
        pageTitle: 'Edit Soal Riasec',
        breadcrumb: [
          {
            text: 'Edit Soal Riasec',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/vak',
      name: 'vak-index',
      component: () => import('@/views/Psikotes/Vak/Index.vue'),
      meta: {
        pageTitle: 'Vak',
        breadcrumb: [
          {
            text: 'Vak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/vak/add',
      name: 'vak-add',
      component: () => import('@/views/Psikotes/Vak/Add.vue'),
      meta: {
        pageTitle: 'Tambah Test Vak',
        breadcrumb: [
          {
            text: 'Tambah Test Vak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/vak/edit/:id',
      name: 'vak-edit',
      component: () => import('@/views/Psikotes/Vak/Edit.vue'),
      meta: {
        pageTitle: 'Edit Test Vak',
        breadcrumb: [
          {
            text: 'Edit Test Vak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/vak/soal/:id_vak_test',
      name: 'vak-soal-index',
      component: () => import('@/views/Psikotes/Vak/Soal/Index.vue'),
      meta: {
        pageTitle: 'Soal Vak',
        breadcrumb: [
          {
            text: 'Soal Vak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/vak/soal-add/:id_vak_test',
      name: 'vak-soal-add',
      component: () => import('@/views/Psikotes/Vak/Soal/Add.vue'),
      meta: {
        pageTitle: 'Tambah Soal Vak',
        breadcrumb: [
          {
            text: 'Tambah Soal Vak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/vak/soal-edit/:id_vak_test/:id',
      name: 'vak-soal-edit',
      component: () => import('@/views/Psikotes/Vak/Soal/Edit.vue'),
      meta: {
        pageTitle: 'Edit Soal Vak',
        breadcrumb: [
          {
            text: 'Edit Soal Vak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/ist',
      name: 'ist-index',
      component: () => import('@/views/Psikotes/Ist/Index.vue'),
      meta: {
        pageTitle: 'Ist',
        breadcrumb: [
          {
            text: 'Ist',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/ist/add',
      name: 'ist-add',
      component: () => import('@/views/Psikotes/Ist/Add.vue'),
      meta: {
        pageTitle: 'Tambah Test Ist',
        breadcrumb: [
          {
            text: 'Tambah Test Ist',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/ist/edit/:id',
      name: 'ist-edit',
      component: () => import('@/views/Psikotes/Ist/Edit.vue'),
      meta: {
        pageTitle: 'Edit Test Ist',
        breadcrumb: [
          {
            text: 'Edit Test Ist',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/ist/soal/:id_ist_test',
      name: 'ist-soal-index',
      component: () => import('@/views/Psikotes/Ist/Soal/Index.vue'),
      meta: {
        pageTitle: 'Soal Ist',
        breadcrumb: [
          {
            text: 'Soal Ist',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/vak/soal-add/:id_vak_test',
      name: 'vak-soal-add',
      component: () => import('@/views/Psikotes/Vak/Soal/Add.vue'),
      meta: {
        pageTitle: 'Tambah Soal Vak',
        breadcrumb: [
          {
            text: 'Tambah Soal Vak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/psikotes/vak/soal-edit/:id_vak_test/:id',
      name: 'vak-soal-edit',
      component: () => import('@/views/Psikotes/Vak/Soal/Edit.vue'),
      meta: {
        pageTitle: 'Edit Soal Vak',
        breadcrumb: [
          {
            text: 'Edit Soal Vak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/testimoni-psikotes',
      name: 'testimoni-psikotes',
      component: () => import('@/views/Psikotes/TestimoniPsikotes.vue'),
      meta: {
        pageTitle: 'Testimoni Psikotes',
        breadcrumb: [
          {
            text: 'Testimoni Psikotes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-passwrd',
      component: () => import('@/views/Reset-password.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  // Check Token
  const token = sessionStorage.getItem('token') ?? null
  if (!token && to.name !== 'login') {
    // redirect the user to the login page
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
